import { Badge } from '@mui/material'

import MenuIcon from 'app/assets/icons/header/menu.svg'
import IconButton from 'app/components/base/IconButton/IconButton'
import useUserNotifications from 'app/hooks/useUserNotifications'
import { useGetChatNotifications } from 'app/services/Query/chat/chat.query'

type BurgerMenuProps = {
  onClickMenu: () => void
}

const BurgerMenuButton: RFC<BurgerMenuProps> = ({ onClickMenu }) => {
  const { isNotifications } = useUserNotifications()
  const { data: messages } = useGetChatNotifications()

  const invisible = !isNotifications && !messages?.unread_messages

  return (
    <>
      <IconButton
        aria-label="menu"
        onClick={onClickMenu}
        sx={{ display: { xs: 'block', cmd: 'none' } }}
      >
        <Badge
          color="secondary"
          variant="dot"
          invisible={invisible}
          sx={{
            '.MuiBadge-badge': {
              top: 6,
              right: 3,
              minWidth: 14,
              height: 14
            }
          }}
        >
          <MenuIcon />
        </Badge>
      </IconButton>
    </>
  )
}

export default BurgerMenuButton
